export default {
  data() {
    return {
      rule_cantBeNegative: (value) => value >= 0 || this.$t("inputRules.cantBeNegative"),
      rule_cantBeNull: (value) => value >= 1 || this.$t("inputRules.cantBeNull"),
      rule_max_decision_length: (value) => value.length <= 240 || this.$t("inputRules.maxTextLength").replace("<length>", 240)
    }
  },  
  computed: {

  },
  methods: {
    cantBeNegative(boolean) {
      if (boolean)
        return this.rule_cantBeNegative;
      return true;
    },
    cantBeNull(boolean) {
      if (boolean)
        return this.rule_cantBeNull;
      return true;
    }
  }
};
