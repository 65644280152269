<template>
  <v-dialog
    v-model="dialog"
    :max-width="460"
    transition="dialog-top-transition"
    content-class="timePickerFixModale"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="px-2 button_blue white--text ml-6"
        v-if="hideButton == false"
        depressed
        small
        @click="setNewEditedMeeting"
      >
        <v-icon small class="pr-1">mdi-plus</v-icon>
        <span>{{ $t("meetings.meetingForm") }}</span>
      </v-btn>
    </template> -->
    <template v-slot:default>
      <v-card class="pa-0">
        <!-- Form Title and close button -->
        <v-card-title
          class="title font-weight-regular justify-space-between px-4 pt-4 pb-0"
        >
          <span>{{ $t("meetings.updateRecurrence") }}</span>
          <v-btn icon @click="closeForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-radio-group v-model="selectedAction">
            <v-radio
              class="mb-2"
              v-for="(action, index) in filteredActions"
              :key="index"
              :label="action.label"
              :value="action.value"
            ></v-radio> </v-radio-group
        ></v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-2">
          <v-btn text @click="closeForm" color="almost_black">
            <v-icon class="pr-1" small>mdi-close</v-icon>
            {{ $t("misc.cancel") }}
          </v-btn>

          <v-spacer></v-spacer>

          <!-- v-if="selectedTab == 'newMeeting' && !rruleString" -->
          <v-btn
            color="success"
            class="white--text"
            depressed
            :disabled="loading"
            @click="submit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "recurrentMeetingSubmitDialog",
  mixins: [],
  components: {},
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    hideOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    hideAll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      actions: [
        {
          label: this.$t("meetings.updateRecurrenceOnlyOne"),
          value: "one",
        },
        {
          label: this.$t("meetings.updateRecurrenceOneAndNext"),
          value: "all_since",
        },
        {
          label: this.$t("meetings.updateRecurrenceOnlyAll"),
          value: "all",
        },
      ],
      selectedAction: "one",
    };
  },
  computed: {
    filteredActions() {
      if (this.hideOnly) {
        return this.actions.filter(e => e.value !== "one");
      } else if (this.hideAll) {
        return this.actions.filter(e => e.value !== "all");
      }
      return this.actions;
    }
    /*  meetingAction() {
      if (this.editedMeeting.id) {
        if (this.editedMeeting.recurrent_meeting_id)
          return this.$t("meetings.updateRecurrentMeeting");
        else return this.$t("meetings.updateMeeting");
      } else {
        if (this.selectedRecurrence != 0)
          return this.$t("meetings.createRecurrentMeeting");
        else return this.$t("meetings.createMeeting");
      }
    } */
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    closeForm() {
      this.dialog = false;
    },
  },
  mounted() {},
  created() {},
  beforeDestroy() {},
  watch: {
    dialog() {},
  },
};
</script>
