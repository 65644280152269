<template>
  <v-dialog v-model="dialog" max-width="540">
    <v-card>
      <v-card-title class="title font-weight-regular justify-space-between">
        <span>{{ $t("calendar.customRecurrence") }}</span>
        <v-btn icon @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- <span class="caption ma-6 text-uppercase" :key="selectedWeekdaysRefreshIndex">{{rruleText}}</span> -->

      <v-card-text class="px-6 pt-0">
        <v-row wrap no-gutters>
          <v-col cols="12">
            <v-subheader class="pl-0">
              {{ $t("calendar.repeatEvery") }}
              <v-text-field
                hide-details
                style="max-width: 90px"
                solo
                filled
                dense
                type="number"
                v-model="repeatEvery"
                flat
                class="ml-4"
              ></v-text-field>

              <v-select
                hide-details
                style="max-width: 140px"
                solo
                filled
                dense
                type="number"
                v-model="selectedRange"
                :items="rangeItems"
                item-value="single"
                :item-text="repeatEvery > 1 ? 'plural' : 'single'"
                flat
                class="ml-4"
              ></v-select>
            </v-subheader>
          </v-col>
        </v-row>

        <template v-if="selectedRange == 'week'">
          <v-row wrap no-gutters class="pb-2">
            <v-col cols="12">
              <v-subheader class="pl-0">
                {{ $t("calendar.repeatOn") }}
              </v-subheader>

              <v-btn
                depressed
                small
                v-for="weekday in weekdays"
                class="mr-2"
                :key="weekday + '_' + selectedWeekdaysRefreshIndex"
                @click="weekdayToggle(weekday)"
                :color="
                  selectedWeekdays[weekday] ? 'button_blue' : 'light_grey'
                "
                :dark="selectedWeekdays[weekday]"
                >{{ $t("calendar.weekdayShort")[weekday] }}</v-btn
              >
            </v-col>
          </v-row>
        </template>

        <v-select
          v-if="selectedRange == 'month'"
          item-value="index"
          item-text="text"
          :menu-props="{ 'offset-y': true }"
          solo
          flat
          filled
          dense
          hide-details
          :items="suggestedMonthlyPatterns"
          v-model="selectedMonthlyPattern"
          class="pt-4 pb-2 recurrenceText"
          style="max-width: 342px"
        ></v-select>

        <v-subheader class="pl-0">
          {{ $t("calendar.ends") }}
        </v-subheader>

        <!-- Never ends -->
        <v-row wrap no-gutters class="pb-2">
          <v-col cols="4">
            <v-btn icon color="button_blue" @click="selectedEndOption = 'never'"
              ><v-icon
                >{{ endsOptionsIcones[+endsOptionIsSelected("never")] }}
              </v-icon></v-btn
            >
            {{ $t("calendar.endsNever") }}
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>

        <!-- End on date -->
        <v-row wrap no-gutters class="pb-2">
          <v-col cols="4">
            <v-btn icon color="button_blue" @click="selectedEndOption = 'on'"
              ><v-icon
                >{{ endsOptionsIcones[+endsOptionIsSelected("on")] }}
              </v-icon></v-btn
            >
            {{ $t("calendar.endsOn") }}
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="dateMenuRec"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="endsOnDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateAsTextShort(endsOnDate)"
                  solo
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  filled
                  flat
                  dense
                  style="max-width: 180px"
                  :disabled="selectedEndOption != 'on'"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endsOnDate"
                no-title
                scrollable
                @change="$refs.dateMenuRec.save(endsOnDate)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- After X occurences -->
        <v-row wrap no-gutters class="mb-4">
          <v-col cols="4">
            <v-btn icon color="button_blue" @click="selectedEndOption = 'after'"
              ><v-icon
                >{{ endsOptionsIcones[+endsOptionIsSelected("after")] }}
              </v-icon></v-btn
            >
            {{ $t("calendar.endsAfter") }}
          </v-col>
          <v-col cols="6">
            <v-text-field
              hide-details
              style="max-width: 180px"
              solo
              filled
              dense
              type="number"
              v-model="occurencesNumber"
              flat
              :disabled="selectedEndOption != 'after'"
            >
              <template slot="append">
                <span class="pl-2">{{
                  occurencesNumber > 1
                    ? $t("calendar.occurences")
                    : $t("calendar.occurence")
                }}</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="cancel()" color="almost_black">
          <v-icon class="pr-1" small>mdi-close</v-icon>
          {{ $t("misc.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="white--text"
          depressed
          @click="saveRecurrence()"
        >
          {{ $t("misc.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from "vuex";
import date from "@/mixins/date.js";
import moment from "moment-timezone";
import { RRule, RRuleSet, rrulestr } from "rrule";

export default {
  name: "CustomRecurrenceSettings",
  mixins: [date],
  components: {},
  props: {
    defaultDayIndex: {
      type: Number,
    },
    startDate: {
      type: String,
    },
    startTime: {
      type: String,
    },
    additionnalMonthlyPatternsSuggestions: {
      type: Array,
      default: () => [],
    },
    endsAt: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      selectedMonthlyPattern: 0,
      dialog: false,
      dateMenu: false,
      repeatEvery: 1,
      selectedRange: "day",
      occurencesNumber: 15,
      endsOnDate: null,
      endsOptionsIcones: ["mdi-radiobox-blank", "mdi-radiobox-marked"],
      rangeItems: [
        {
          single: "day",
          plural: "days",
          freq: RRule.DAILY,
        },
        {
          single: "week",
          plural: "weeks",
          freq: RRule.WEEKLY,
        },
        {
          single: "month",
          plural: "months",
          freq: RRule.MONTHLY,
        },
        {
          single: "year",
          plural: "years",
          freq: RRule.YEARLY,
        },
      ],
      selectedEndOption: "never",
      selectedWeekdays: [false, false, false, false, false, false, false],
      selectedWeekdaysRefreshIndex: 0,
      weekdays: [0, 1, 2, 3, 4, 5, 6],
    };
  },
  computed: {
    ...mapGetters([]),
    suggestedMonthlyPatterns() {
      var suggestedRecurrences = [];

      // Weekly
      suggestedRecurrences.push({
        value: "monthlyOn",
        index: 0,
        text: this.$t("calendar.recurrence.monthlyOn").replace(
          "<day>",
          moment(`${this.startDate} ${this.startTime}`).utc().format("DD")
        ),
      });

      suggestedRecurrences = suggestedRecurrences.concat(
        this.additionnalMonthlyPatternsSuggestions
      );
      return suggestedRecurrences;
    },
    rrule() {
      const days = [
        RRule.MO,
        RRule.TU,
        RRule.WE,
        RRule.TH,
        RRule.FR,
        RRule.SA,
        RRule.SU,
      ];

      const curr_day = moment(`${this.startDate} ${this.startTime}`)
        .utc()
        .day();

      var refresh = this.selectedWeekdaysRefreshIndex; //fix computed no refreshing
      var bysetpos = null;

      if (this.selectedMonthlyPattern != 0) {
        var value = this.suggestedMonthlyPatterns.find(
          (f) => f.index == this.selectedMonthlyPattern
        ).value;

        if (value == "monthlyOnLast") bysetpos = [-1];
        else {
          const _pattern = [
            "monthlyOnFirst",
            "monthlyOnSecond",
            "monthlyOnThird",
            "monthlyOnFourth",
          ];
        bysetpos = _pattern.indexOf(value)+1

        }
      }
      var selectedRRuleDays = days.filter(
        (d, i) => this.selectedWeekdays[i] == true
      );

      var byweekdays = null; // default every days

      if (this.selectedRange == "week") {
        // custom list of days
        byweekdays = selectedRRuleDays;
      } else if (this.selectedRange == "month" && this.selectedMonthlyPattern != 0)
      {
        byweekdays = days[curr_day - 1]
      }

      return new RRule({
        freq: this.rangeItems.find((ri) => ri.single == this.selectedRange)
          .freq,
        count: this.selectedEndOption == "after" ? this.occurencesNumber : null,
        until:
          this.selectedEndOption == "on"
            ? moment(`${this.endsOnDate} ${this.startTime}`).utc().toDate()
            : null,
        interval: this.repeatEvery,
        byweekday: byweekdays,
        bysetpos: bysetpos,
      });
    },
    rruleString() {
      if (this.rrule) return this.rrule.toString();
      return "";
    },
    rruleText() {
      if (this.rrule) return this.rrule.toText();
      return "";
    },
  },
  methods: {
    endsOptionIsSelected(endsOption) {
      return this.selectedEndOption == endsOption;
    },
    cancel() {
      this.dialog = false;
      this.reset();
      this.$emit("cancel");
    },
    saveRecurrence() {
      this.$emit("saveRecurrence", this.rrule);
      this.dialog = false;
    },
    weekdayToggle(weekDayIndex) {
      this.selectedWeekdays[weekDayIndex] =
        !this.selectedWeekdays[weekDayIndex];

      if (!this.selectedWeekdays.includes(true)) {
        this.setDefaultSelectedWeekday();
      }

      this.selectedWeekdaysRefreshIndex++;
    },
    setDefaultSelectedWeekday() {
      this.selectedWeekdays = [false, false, false, false, false, false, false];
      this.selectedWeekdays[this.defaultDayIndex] = true;
    },
    reset() {
      this.selectedMonthlyPattern = 0;
      this.dateMenu = false;
      this.repeatEvery = 1;
      this.selectedRange = "day";
      this.occurencesNumber = 15;
      this.selectedWeekdaysRefreshIndex = 0;
      this.selectedWeekdays[this.defaultDayIndex] = true;

      if (this.endsAt) {
        this.selectedEndOption = "on";
        this.endsOnDate = moment(this.endsAt).utc().format("YYYY-MM-DD");
      } else {
        this.selectedEndOption = "never";
        this.endsOnDate = moment().add(3, "months").format("YYYY-MM-DD");
      }
    }
  },
  mounted() {
    this.reset()
  },
  watch: {
    dialog(value) {
      if (value) this.reset();
    },
    defaultDayIndex() {
      this.setDefaultSelectedWeekday();
    },
  },
};
</script>

