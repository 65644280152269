<template>
  <v-dialog
    v-model="dialog"
    :max-width="dialogWidth"
    transition="dialog-top-transition"
    content-class="timePickerFixModale"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="px-2 button_blue white--text ml-6"
        v-if="hideButton == false"
        depressed
        small
        @click="setNewEditedMeeting"
      >
        <v-icon small class="pr-1">mdi-plus</v-icon>
        <span>{{ $t("meetings.meetingForm") }}</span>
      </v-btn>
    </template>
    <template v-slot:default>
      <recurrent-meeting-submit-dialog
        ref="recurrentMeetingSubmitDialog"
        :loading="loading"
        :hideOnly="recurrenceOrEndAtHasChanged"
        :hideAll="startDayChanged"
        @submit="submitRecurrentMeetingDialog"
      />
      <v-card class="pa-0">
        <!-- Form Title and close button -->
        <!-- <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ $t("meetings.meetingForm") }}</span>
          <v-btn icon @click="closeForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title> -->

        <v-card-title class="pa-4 justify-space-around">
          <v-row wrap no-gutters align="center">
            <v-col cols="auto" class="grow">
              <v-text-field
                v-model="meetingTitle"
                background-color="transparent"
                :placeholder="$t('meetings.addATitle')"
                class="
                  title
                  cardTitlePlaceholder
                  focusedCardEditorField
                  mr-4
                  ml-2
                "
                hide-details
                solo
                flat
                :autofocus="meetingTitle == ''"
                autocomplete="off"
                ref="meeting-title"
              >
                <template v-slot:append>
                  <!-- Update color -->
                  <v-menu bottom left>
                    <template #activator="menu">
                      <v-tooltip left color="black">
                        <template #activator="tooltip">
                          <v-btn
                            small
                            icon
                            :color="meetingColor"
                            v-on="{ ...menu.on, ...tooltip.on }"
                          >
                            <v-icon>mdi-circle</v-icon></v-btn
                          >
                        </template>

                        <span>{{ $t("meetings.changeMeetingsColor") }}</span>
                      </v-tooltip>
                    </template>

                    <v-list dense subheader>
                      <!-- <v-subheader>
                              {{ $t("meetings.meetingColor") }}
                            </v-subheader> -->
                      <v-list-item
                        v-for="(item, i) in selectableColors"
                        :key="i"
                        @click="meetingColor = item"
                        style="text-align: center"
                      >
                        <v-list-item-title class="mr-0"
                          ><v-icon
                            class="mx-auto"
                            :color="item"
                            :small="meetingColor != item"
                            left
                            >mdi-circle</v-icon
                          >
                          <!-- {{
                                  $t("misc.colors." + item)
                                }}
                                 -->
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="auto" class="shrink justify-space-around" align="end">
              <v-btn @click="closeForm()" small depressed icon
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <template v-if="!loading">
          <v-card-text
            id="cardEditorScrollArea"
            class="cardEditorScrollableContent px-4 pt-2"
          >
            <v-row wrap no-gutters>
              <v-col cols="12" md="6">
                <v-row wrap no-gutters>
                  <!-- Start Date -->
                  <v-col cols="12" class="px-2">
                    <v-subheader class="pl-2 caption" style="height: 24px">
                      {{ $t("meetings.startDate") }}
                    </v-subheader>
                    <v-menu
                      ref="dateMenu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="meetingDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="
                            meetingDate == todaysDate
                              ? $t('misc.today')
                              : dateAsText(meetingDate)
                          "
                          solo
                          flat
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          filled
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="meetingDate"
                        no-title
                        scrollable
                        @change="$refs.dateMenu.save(meetingDate)"
                      >
                        <!-- <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateMenu = false">
                          {{ $t("misc.cancel") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dateMenu.save(meetingDate)"
                        >
                          {{ $t("misc.ok") }}
                        </v-btn> -->
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Start Time -->
                  <v-col cols="6" class="px-2">
                    <v-subheader class="pl-2 caption" style="height: 24px">
                      {{ $t("meetings.startTime") }}
                    </v-subheader>
                    <div
                      class="
                        v-input
                        v-input--is-label-active
                        v-input--is-dirty
                        v-input--is-readonly
                        v-input--dense
                        theme--light
                        v-text-field
                        v-text-field--filled
                        v-text-field--is-booted
                        v-text-field--enclosed
                        v-text-field--single-line
                        v-text-field--solo
                        v-text-field--solo-flat
                      "
                    >
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <div class="v-text-field__slot">
                            <vue-timepicker
                              name="timeInput"
                              :minute-interval="5"
                              manual-input
                              input-width="100%"
                              input-class="timePicker"
                              height="52px"
                              v-model="meetingStartTime"
                              hide-clear-button
                              drop-offset-height="48px"
                            >
                            </vue-timepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <!-- Duration -->
                  <v-col cols="6" class="px-2">
                    <v-subheader class="pl-2 caption" style="height: 24px">
                      {{ $t("meetings.durationMinutesOptional") }}
                    </v-subheader>
                    <v-text-field
                      v-model.number="meetingDuration"
                      placeholder=""
                      filled
                      dense
                      solo
                      flat
                      autocomplete="off"
                      type="number"
                      :rules="[cantBeNegative(true)]"
                      min="0"
                      step="1"
                      @change="roundDuration"
                      :suffix="$t('meetings.inMinutes')"
                    >
                    </v-text-field>
                  </v-col>

                  <!-- Recurrence presets -->
                  <v-col cols="12" class="px-2">
                    <v-subheader class="pl-2 caption" style="height: 24px">
                      {{ $t("calendar.recurrence.repeat") }}
                    </v-subheader>
                    <v-select
                      item-value="index"
                      item-text="text"
                      :menu-props="{ 'offset-y': true }"
                      solo
                      flat
                      filled
                      dense
                      :items="suggestedRecurrencesPresets"
                      v-model="selectedRecurrence"
                    ></v-select>
                  </v-col>

                  <!-- Timed topics -->
                  <!-- <v-col cols="12" class="px-2">
                    <v-list dense two-line>
                      <v-list-item>
                        <v-list-item-action class="mr-4">
                          <v-switch
                            class="getcollective_switch"
                            dense
                            inset
                            v-model="timedTopics"
                            color="primary"
                          ></v-switch>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            $t("meetings.timedTopics")
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            $t("meetings.timedTopicsDescription")
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col> -->

                  <!-- <v-col cols="12" class="px-2 pb-2">
                    <v-subheader class="pl-2 caption" style="height: 24px">
                      {{ $t("templates.template") }}
                    </v-subheader>
                    <v-autocomplete
                      :disabled="templatesLoading"
                      :loading="templatesLoading"
                      v-model="selectedTemplateId"
                      :items="templates"
                      item-value="id"
                      item-text="name"
                      dense
                      filled
                      solo
                      flat
                      :label="
                        templatesLoading
                          ? $t('templates.loadingTemplates')
                          : $t('templates.selectTemplate')
                      "
                    ></v-autocomplete
                  ></v-col> -->
                </v-row>
              </v-col>

              <v-col cols="12" md="6" class="pl-6">
                <div class="stickySidebar px-2">
                  <v-row wrap no-gutters>
                    <v-col cols="6" md="12" :key="meetingUserIds.join('')">
                      <v-subheader class="pl-2 caption" style="height: 24px">
                        <!-- <v-icon small class="pr-2">mdi-account-multiple</v-icon
                        >
                         -->
                        {{
                          $t("meetings.participants") +
                          " (" +
                          meetingUserIds.length +
                          ")"
                        }}
                      </v-subheader>
                      <searchAndSelectUsers
                        :selectedUsersIds="meetingUserIds"
                        @onUserSelected="addUser"
                        @onUserUnselected="removeUser"
                        :selectedMessage="$t('meetings.invitedToMeeting')"
                        :placeholder="$t('meetings.inviteParticipants')"
                      />

                      <v-list
                        dense
                        avatar
                        class="py-0"
                        style="width: 100%; max-height: 400px"
                      >
                        <userItem
                          v-for="user_id in meetingUserIds"
                          :key="user_id"
                          :user="users_set[user_id]"
                          dense
                          condensed
                        >
                          <template slot="custom">
                            <!-- <v-list-item-subtitle
                        class="body-4"
                        v-if="user_id == meetingUserIds"
                      >
                        {{ $t("meetings.meetingOrganizer") }}
                      </v-list-item-subtitle> -->
                          </template>

                          <template v-slot:action>
                            <v-btn
                              icon
                              @click="removeUser(user_id)"
                              :disabled="loading"
                            >
                              <v-icon color="error" small
                                >mdi-account-minus</v-icon
                              >
                            </v-btn>
                          </template>
                        </userItem>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>

            <!-- History -->
            <!-- <div class="mb-8" v-if="card.id && false">
        <h5 class="pl-4" small color="light_grey">
          <v-icon
            small
            class="pr-2"
            color="dark_grey"
            style="padding-bottom: 2px"
          >
            mdi-history
          </v-icon>
          {{ $t("meetings.history") }}
        </h5>
      </div> -->
          </v-card-text>
        </template>
        <v-card-text v-else class="text-xs-center d-flex justify-center pa-8">
          <v-row wrap no-gutters>
            <v-col class="text-xs-center d-flex justify-center" cols="12"
              ><v-progress-circular
                color="button_blue"
                indeterminate
                size="48"
                width="4"
              ></v-progress-circular
            ></v-col>
            <v-col class="text-xs-center d-flex justify-center" cols="12">
              <v-card-title class="pa-0 pt-4">
                {{ $t("meetings.creatingMeeting") }}
              </v-card-title></v-col
            >
            <v-col class="text-xs-center d-flex justify-center" cols="12">
              <v-card-subtitle class="pa-0">
                {{ $t("misc.pleaseWait") }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <!-- <v-card-text class="pt-4">
          SELECTED PATTERN: {{ selectedPatternValue }}
          <br />
          RRULE : {{ rrule }}
          <br />
          RRUE STRING: {{ rruleString }}
          <br />
          RRUE TEXT: {{ rruleText }}
          <br />
          previousSelectedRecurrence: {{ previousSelectedRecurrence }}
          <br />
          selectedRecurrence: {{ selectedRecurrence }},
          <br>
          currentDayIndex {{currentDayIndex}}
          <br>
          this.meetingColor {{meetingColor}}
          <br>
          monthlyPatterns {{monthlyPatterns}}
        </v-card-text> -->

        <!--
        <span class="caption">SELECTED selectedDay: {{selectedDay}} <br>
        new Date(selectedDay).toISOString().substr(0, 10) {{new Date(selectedDay).toISOString().substr(0, 10)}} <br>

        todaysDate: |{{todaysDate}}<br>
        {{meetingStartTime}}</span> -->
        <v-card-actions>
          <v-btn
            text
            @click="closeForm()"
            color="almost_black"
            :disabled="loading"
          >
            <v-icon class="pr-1" small>mdi-close</v-icon>
            {{ $t("misc.cancel") }}
          </v-btn>

          <v-spacer></v-spacer>

          <!-- v-if="selectedTab == 'newMeeting' && !rruleString" -->
          <v-btn
            color="success"
            class="white--text"
            depressed
            :disabled="!canCreateMeeting"
            @click="saveMeeting()"
          >
            {{ meetingAction }}
          </v-btn>

          <!--  <v-btn
            v-if="selectedTab == 'newMeeting' && rruleString"
            color="success"
            class="white--text"
            depressed
            :disabled="!canCreateMeeting || loading"
            @click="updateRecurrentMeeting()"
          >
          </v-btn>

          <v-btn
            v-if="selectedTab == 'loadTemplate'"
            :disabled="!canCreateMeeting || loading"
            color="success"
            class="white--text"
            depressed
            @click="saveMeeting()"
          >
          </v-btn> -->
        </v-card-actions>
      </v-card>

      <custom-recurrence-settings
        ref="customRecurrenceSettings"
        @cancel="selectedRecurrence = previousSelectedRecurrence"
        :defaultDayIndex="currentDayIndex"
        :startDate="meetingDate"
        :startTime="meetingStartTime"
        :endsAt="editedMeeting.recurrent_meeting ? editedMeeting.recurrent_meeting.ends_at : ''"
        :additionnalMonthlyPatternsSuggestions="monthlyPatterns"
        @saveRecurrence="handleCustomRecurrence"
      />
    </template>
  </v-dialog>
</template>

<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import rules from "@/mixins/rules";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import { RRule, RRuleSet, rrulestr } from "rrule";

import date from "@/mixins/date.js";
import userAvatar from "../shared/userAvatar";
import CustomRecurrenceSettings from "./CustomRecurrenceSettings.vue";
import searchAndSelectUsers from "@/components/users/searchAndSelectUsers";
import userItem from "@/components/users/userItem";

import CREATE_MEETING from "@/graphql/meetings/create.gql";
import UPDATE_MEETING from "@/graphql/meetings/update.gql";
import CREATE_RECURRENT_MEETING from "@/graphql/recurrent_meetings/create.gql";
import UPDATE_RECURRENT_MEETING from "@/graphql/recurrent_meetings/update.gql";
import MEETING_TEMPLATES_INDEX from "@/graphql/meeting_templates/paginated_index.gql";
import RecurrentMeetingSubmitDialog from "./recurrentMeetingSubmitDialog.vue";

export default {
  name: "meetingForm",
  mixins: [date, rules],
  components: {
    userAvatar,
    VueTimepicker,
    CustomRecurrenceSettings,
    searchAndSelectUsers,
    userItem,
    RecurrentMeetingSubmitDialog,
  },
  props: {
    miniVariant: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedDay: {
      type: [Number, String, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      selectedTab: "newMeeting",
      meetingTitle: "",
      meetingDescription: "",
      formStep: 0,
      timedTopics: false,
      dateMenu: false,
      dateMenu2: false,
      todaysDate: new Date().toISOString().substr(0, 10),
      meetingDate: new Date().toISOString().substr(0, 10),
      oldStartsAt: "",
      oldRuleString: "",
      oldMeetingDate: "",
      meetingStartTime: "",
      meetingEndTime: "",
      meetingDuration: 0,
      meetingColor: "blue",
      meetingUserIds: [],
      previousSelectedRecurrence: 0,
      customRRule: null,
      selectedRecurrence: 0, // Using index and not value to handle date switching to different patterns while keeping the correct position selected
      // recurrences: [
      //   "doesNotRepeat",
      //   "daily",
      //   "weeklyOn",
      //   "monthlyOn",
      //   "monthlyOnFirst",
      //   "monthlyOnSecond",
      //   "monthlyOnThird",
      //   "monthlyOnFourth",
      //   "monthlyOnLast",
      //   "annuallyOn",
      //   "everyWeekDay",
      //   "custom",
      // ],
      selectableColors: [
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "cyan",
        "teal",
        "green",
        "lime",
        "yellow",
        "orange",
      ],
      loading: false,
      templatesLoading: false,
      templates: [],
      selectedTemplateId: "",

    };
  },
  computed: {
    ...mapGetters([
      "selectedOrganisationId",
      "organisation_recurrent_meeting_ids",
      "organisation_recurrent_meetings",
      "recurrent_meetings_set",
      "users_set",
      "editedMeeting",
    ]),

    meetingAction() {
      if (this.editedMeeting.id) {
        if (this.editedMeeting.recurrent_meeting_id)
          return this.$t("meetings.updateRecurrentMeeting");
        else return this.$t("meetings.updateMeeting");
      } else {
        if (this.selectedRecurrence != 0)
          return this.$t("meetings.createRecurrentMeeting");
        else return this.$t("meetings.createMeeting");
      }
    },
    currentDayIndex() {
      return (
        moment(`${this.meetingDate} ${this.meetingStartTime}`).utc().day() - 1
      );
    },
    selectedPatternValue() {
      var sr = this.suggestedRecurrencesPresets.find((sr) => {
        return sr.index == this.selectedRecurrence;
      });
      if (sr) return sr.value;
      return "";
    },
    rrule() {
      if (this.selectedPatternValue == "doesNotRepeat") return ""; // doesNotRepeat means we don't need any rrule.

      if (this.customRRule)
        // We have a RRule created in the CUSTOM SETTINGS
        return this.customRRule;

      const days = [
        RRule.MO,
        RRule.TU,
        RRule.WE,
        RRule.TH,
        RRule.FR,
        RRule.SA,
        RRule.SU,
      ];
      const curr_day = moment(`${this.meetingDate} ${this.meetingStartTime}`)
        .utc()
        .day();

      // Init rrule start date to UTC STRING
      var _dstart = new Date(
        this.formatStrDate(`${this.meetingDate} ${this.meetingStartTime}`)
      );

      if (this.selectedPatternValue == "daily") {
        return new RRule({
          freq: RRule.DAILY,
        });
      } else if (this.selectedPatternValue == "weeklyOn") {
        return new RRule({
          freq: RRule.WEEKLY,
        });
      } else if (this.selectedPatternValue == "annuallyOn") {
        return new RRule({
          freq: RRule.YEARLY,
        });
      } else if (this.selectedPatternValue == "everyWeekDay") {
        return new RRule({
          freq: RRule.WEEKLY,
          byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
        });
      } else if (this.selectedPatternValue == "monthlyOnFirst") {
        return new RRule({
          freq: RRule.MONTHLY,
          byweekday: days[curr_day - 1],
          bysetpos: [1],
        });
      } else if (this.selectedPatternValue == "monthlyOnSecond") {
        return new RRule({
          freq: RRule.MONTHLY,
          byweekday: days[curr_day - 1],
          bysetpos: [2],
        });
      } else if (this.selectedPatternValue == "monthlyOnThird") {
        return new RRule({
          freq: RRule.MONTHLY,
          byweekday: days[curr_day - 1],
          bysetpos: [3],
        });
      } else if (this.selectedPatternValue == "monthlyOnFourth") {
        return new RRule({
          freq: RRule.MONTHLY,
          byweekday: days[curr_day - 1],
          bysetpos: [4],
        });
      } else if (this.selectedPatternValue == "monthlyOnLast") {
        return new RRule({
          freq: RRule.MONTHLY,
          byweekday: days[curr_day - 1],
          bysetpos: [-1],
        });
      }

      return "";
    },
    rruleString() {
      if (this.rrule) return this.rrule.toString();
      return "";
    },
    rruleText() {
      if (this.rrule) return this.capitalizeFirstLetter(this.rrule.toText());
      return "";
    },
    recurrenceOrEndAtHasChanged() {
      if (!this.editedMeeting.recurrent_meeting) return false;
      return this.rruleString !== this.oldRuleString;
    },
    startDayChanged() {
      if (!this.editedMeeting.recurrent_meeting) return false;
      return this.oldMeetingDate !== this.meetingDate;
    },
    monthlyPatterns() {
      return this.suggestedRecurrencesPresets.filter(
        (srp) => srp.index == "3" || srp.index == "4"
      );
    },
    suggestedRecurrencesPresets() {
      var suggestedRecurrences = [];

      // Does not repeat
      suggestedRecurrences.push({
        value: "doesNotRepeat",
        text: this.$t("calendar.recurrence.doesNotRepeat"),
        index: 0,
      });

      // Daily
      suggestedRecurrences.push({
        value: "daily",
        text: this.$t("calendar.recurrence.daily"),
        index: 1,
      });

      // Weekly
      suggestedRecurrences.push({
        value: "weeklyOn",
        index: 2,
        text: this.$t("calendar.recurrence.weeklyOn").replace(
          "<day>",
          this.getDayName(this.meetingDate, "YYYY-MM-DD")
        ),
      });

      // if First, Second, Third, Fourth
      const _pattern = [
        "monthlyOnFirst",
        "monthlyOnSecond",
        "monthlyOnThird",
        "monthlyOnFourth",
      ];
      var _allOccurenceOfDay = this.allOccurenceOfADayInAMonth(
        this.meetingDate,
        "YYYY-MM-DD"
      );

      var indexOfNth = _allOccurenceOfDay.indexOf(this.meetingDate);

      // console.log("_allOccurenceOfDay", _allOccurenceOfDay);
      // console.log("this.meetingDate", this.meetingDate);
      // console.log("indexOfNth", indexOfNth);

      if (indexOfNth != -1) {
        if (indexOfNth < 4) {
          suggestedRecurrences.push({
            value: _pattern[indexOfNth],
            index: 3,
            text: this.$t(
              "calendar.recurrence." + _pattern[indexOfNth]
            ).replace("<day>", this.getDayName(this.meetingDate, "YYYY-MM-DD")),
          });
        }

        // Add if it's the last occurence of the month
        if (
          this.meetingDate == _allOccurenceOfDay[_allOccurenceOfDay.length - 1]
        ) {
          suggestedRecurrences.push({
            value: "monthlyOnLast",
            index: 4,
            text: this.$t("calendar.recurrence.monthlyOnLast").replace(
              "<day>",
              this.getDayName(this.meetingDate, "YYYY-MM-DD")
            ),
          });
        } else {
          // EDGE CASE:  If it's not the last occurence of the month, but the previous selected date was, then we switch back to the Nth pattern
          if (this.selectedRecurrence == 4) {
            // console.log(
            //   "Edge case: it's not the last occurence of the month, but the previous selected date was, switching to the Nth pattern"
            // );
            this.selectedRecurrence = 3;
          }
        }
      }

      //EDGE CASE: If it's the 5th occurence, but previous was 1sr, 2nd, 3rd, or 4th, select the "monthlyOnLast"
      // as there is no a 5th of the month option as there is possibility that there is only 4 occurence in a month and not 5.
      if (indexOfNth == 4) {
        // console.log("Edge case: the 5th occurence is also the last one");
        if (this.selectedRecurrence == 3) this.selectedRecurrence = 4;
        // console.log("this.selectedRecurrence", this.selectedRecurrence, suggestedRecurrences)
      }

      // Annualy
      var annualy_text = this.$t("calendar.recurrence.annuallyOn");
      var moment_result = this.getDayNumberAndMonthName(this.meetingDate);
      suggestedRecurrences.push({
        value: "annuallyOn",
        index: 5,
        text: annualy_text
          .replace("<day-number>", moment_result[0])
          .replace("<month>", moment_result[1]),
      });

      // Every Weekday
      suggestedRecurrences.push({
        value: "everyWeekDay",
        text: this.$t("calendar.recurrence.everyWeekDay"),
        index: 6,
      });

      //  CUSTOMISED RRULES will be index 7
      if (this.customRRule) {
        // Every Weekday
        suggestedRecurrences.push({
          value: "customMadeRRule",
          text: this.capitalizeFirstLetter(this.customRRule.toText()),
          index: 7,
        });
      }

      // CUSTOM BUTTON will be index 8
      suggestedRecurrences.push({
        value: "go_to_custom",
        text: this.$t("calendar.recurrence.custom"),
        index: 8,
      });

      return suggestedRecurrences;
    },
    canCreateMeeting() {
      if (this.loading) return false;
      if (!this.validHoursMinutes(this.meetingStartTime)) return false;
      if (this.selectedTab === "newMeeting") {
        return (
          this.meetingDate && this.meetingTitle && this.meetingDuration >= 0
        );
      }
      return this.selectedTemplateId ? true : false;
    },
    dialogWidth() {
      switch (this.selectedTab) {
        case "newMeeting":
          return 760;
        case "loadTemplate":
          return 760;
      }
    },
    recurrenceSet() {
      return !!this.rrule;
    },
  },
  methods: {
    ...mapActions([
      "setInstance",
      "setEditedMeeting",
      "setMeeting",
      "refreshModel",
      "removeInstance",
    ]),
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    addUser(user) {
      this.meetingUserIds.push(user.id);
    },
    removeUser(userId) {
      this.meetingUserIds = this.meetingUserIds.filter((e) => e != userId);
      console.log("this.meetingUserIds", this.meetingUserIds);
    },
    closeForm() {
      this.dialog = false;
    },
    handleCustomRecurrence(rrule) {
      this.customRRule = rrule;
      this.selectedRecurrence = 7;
    },
    validHoursMinutes(timestr) {
      return /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(timestr);
    },
    roundDuration() {
      this.meetingDuration = Math.round(this.meetingDuration);
    },
    submitRecurrentMeetingDialog() {
      let selectedAction =
        this.$refs.recurrentMeetingSubmitDialog.selectedAction;
      if (selectedAction === "one") {
        /* Create one ocurrence of a recurrent meeting */
        if (this.editedMeeting.id.startsWith("virtual")) {
          this.createMeeting();
        } else if (this.editedMeeting.id) {
          /* Update one ocurrence of a recurrent meeting */
          this.updateMeeting();
        }
      } else if (selectedAction === "all_since") {
        /* Update all the series of recurrent meeting from this one */
        this.createRecurrentMeeting();
      } else if (selectedAction === "all") {
        /* Update all the series of recurrent meeting */
        this.updateRecurrentMeeting();
      }
    },
    updateRecurrentMeeting() {
      if (this.loading) return;
      this.loading = true;
      let starts_at = this.formatStrDate(
        `${this.meetingDate} ${this.meetingStartTime}`
      );
      let objid;
      if (
        this.editedMeeting.recurrent_meeting &&
        this.editedMeeting.recurrent_meeting.recurrent_meeting_root_id
      )
        objid = this.editedMeeting.recurrent_meeting.recurrent_meeting_root_id;
      else objid = this.editedMeeting.recurrent_meeting_id;

      let vars =  {
        id: objid,
        title: this.meetingTitle,
        starts_at: starts_at,
        duration: this.meetingDuration,
        timed_topics: this.timedTopics,
        recurring: this.rruleString,
        organisation_id: this.selectedOrganisationId,
        color: this.meetingColor,
        user_ids: this.meetingUserIds,
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_RECURRENT_MEETING,
          variables: vars,
        })
        .then(({ data }) => {
          this.dialog = false;
          this.loading = false;

          this.organisation_recurrent_meetings
            .filter(
              (e) => e.recurrent_meeting_root_id === objid || e.id === objid
            )
            .forEach((e) => {
              this.removeInstance({
                model: "recurrent_meeting",
                instance_id: e.id,
              });
            });

          this.setInstance({
            model: "recurrent_meeting",
            instance: data.update_recurrent_meeting,
          });
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    sortByStartsAt(a, b) {
      return moment(a.starts_at) > moment(b.starts_at) ? 1 : -1;
    },
    rehydrateSeries(root) {
      /* We have created a serie to update all element since ... on a old serie */
      if (!root) return;
      /* Remove old series recurrent meetings */
      this.organisation_recurrent_meetings
        .filter(
          (e) => e.recurrent_meeting_root_id === root.id || e.id === root.id
        )
        .sort(this.sortByStartsAt)
        .forEach((e) => {
          this.removeInstance({
            model: "recurrent_meeting",
            instance_id: e.id,
          });
        });
      this.setInstance({ model: "recurrent_meeting", instance: root });
    },
    createRecurrentMeeting() {
      if (this.loading) return;

      this.loading = true;
      let starts_at = this.formatStrDate(
        `${this.meetingDate} ${this.meetingStartTime}`
      );
      let vars = {
        title: this.meetingTitle,
        starts_at: starts_at,
        duration: this.meetingDuration,
        timed_topics: this.timedTopics,
        recurring: this.rruleString,
        organisation_id: this.selectedOrganisationId,
        color: this.meetingColor,
        user_ids: this.meetingUserIds,
      };
      if (
        this.editedMeeting.id &&
        !this.editedMeeting.id.includes("virtual") &&
        !this.editedMeeting.recurrent_meeting_id
      ) {
        /* We are creating a recurrent meeting based on an already created simple meeting */
        vars.imported_meeting_id = this.editedMeeting.id;
      } else if (
        this.$refs.recurrentMeetingSubmitDialog.selectedAction === "all_since"
      ) {
        /*
          We are updating a part of a serie -> to do this we create another serie
          The parent serie will be updated to end at oldStartsAt - 1 day
        */
        if (
          this.editedMeeting.recurrent_meeting &&
          this.editedMeeting.recurrent_meeting.recurrent_meeting_root_id
        )
          vars.recurrent_meeting_root_id =
            this.editedMeeting.recurrent_meeting.recurrent_meeting_root_id;
        else
          vars.recurrent_meeting_root_id =
            this.editedMeeting.recurrent_meeting_id;
        vars.old_starts_at = moment(this.oldStartsAt)
          .subtract(1, "days")
          .format();
      }
      this.$apollo
        .mutate({
          mutation: CREATE_RECURRENT_MEETING,
          variables: vars,
        })
        .then(({ data }) => {
          this.dialog = false;
          this.loading = false;

          if (data.create_recurrent_meeting.recurrent_meeting_root)
            this.rehydrateSeries(
              data.create_recurrent_meeting.recurrent_meeting_root
            );
          else {
            this.setInstance({
              model: "recurrent_meeting",
              instance: data.create_recurrent_meeting,
            });
          }

          if (
            this.editedMeeting.id &&
            !this.editedMeeting.id.includes("virtual")
          ) {
            this.setMeeting({
              id: this.editedMeeting.id,
              created_at: this.editedMeeting.created_at,
              started_at: this.editedMeeting.started_at,
              status: this.editedMeeting.status,
              title: data.create_recurrent_meeting.title,
              starts_at: data.create_recurrent_meeting.starts_at,
              duration: data.create_recurrent_meeting.duration,
              color: data.create_recurrent_meeting.color,
              user_id: data.create_recurrent_meeting.user_id,
              user_ids: data.create_recurrent_meeting.user_ids,
              title: data.create_recurrent_meeting.title,
              recurrent_meeting_id: data.create_recurrent_meeting.id,
              recurrent_meeting: {
                id: data.create_recurrent_meeting.id,
                recurring: data.create_recurrent_meeting.recurring,
              },
              recurrent_meeting_starts_at:
                data.create_recurrent_meeting.starts_at,
            });
          }
          this.refreshModel("recurrent_meetings");
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    saveMeeting() {
      if (this.loading) return;

      if (!this.editedMeeting.id) {
        /* Create */
        if (!this.recurrenceSet) {
          /* Create a simple meeting */
          this.createMeeting();
        } else {
          /* Create a recurrent meeting */
          this.createRecurrentMeeting();
        }
      } else {
        /* Update */
        if (!this.editedMeeting.recurrent_meeting_id) {
          /* Update a simple meeting */
          if (!this.recurrenceSet) {
            /* Update a simple meeting without setting a recurrence */
            this.updateMeeting();
          } else {
            /* Update a simple meeting setting a recurrence -> create a recurrent meeting */
            this.createRecurrentMeeting();
          }
        } else {
          /*
            We are updating a recurrent meeting
             -> open recurrent meeting submiter dialog to know if the user want to update
                - one event
                - all events until this one
                - all events
          */
          this.$refs.recurrentMeetingSubmitDialog.dialog = true;
        }
      }
    },
    updateMeeting() {
      if (this.loading) return;

      this.loading = true;
      console.log(`${this.meetingDate} ${this.meetingStartTime}`);
      let starts_at = this.formatStrDate(
        `${this.meetingDate} ${this.meetingStartTime}`
      );

      this.$apollo
        .mutate({
          mutation: UPDATE_MEETING,
          variables: {
            id: this.editedMeeting.id,
            meeting_template_id: this.selectedTemplateId,
            title: this.meetingTitle,
            starts_at: starts_at,
            duration: this.meetingDuration,
            timed_topics: this.timedTopics,
            organisation_id: this.selectedOrganisationId,
            color: this.meetingColor,
            user_ids: this.meetingUserIds,
          },
        })
        .then(({ data }) => {
          this.dialog = false;
          this.loading = false;
          this.sendSuccess(this.$t("meetings.updated"));
          this.setMeeting(data.update_meeting);
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    createMeeting() {
      if (this.loading) return;

      this.loading = true;
      console.log(`${this.meetingDate} ${this.meetingStartTime}`);
      let starts_at = this.formatStrDate(
        `${this.meetingDate} ${this.meetingStartTime}`
      );
      let vars = {
        meeting_template_id: this.selectedTemplateId,
        title: this.meetingTitle,
        starts_at: starts_at,
        duration: this.meetingDuration,
        timed_topics: this.timedTopics,
        organisation_id: this.selectedOrganisationId,
        color: this.meetingColor,
        user_ids: this.meetingUserIds,
        recurrent_meeting_id: this.editedMeeting.recurrent_meeting_id,
        recurrent_meeting_starts_at: this.oldStartsAt,
      };

      this.$apollo
        .mutate({
          mutation: CREATE_MEETING,
          variables: vars,
        })
        .then(({ data }) => {
          this.dialog = false;
          this.loading = false;
          this.setMeeting(data.create_meeting);
          this.$amplitude.getInstance().logEvent("Create meeting", {
            name: this.meetingTitle,
            id: data.create_meeting.id,
          });
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    loadTemplates() {
      this.templatesLoading = true;

      this.$apollo
        .query({
          query: MEETING_TEMPLATES_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
            page: 1,
            per_page: -1,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.templates = data.paginated_meeting_templates.data;
          this.templatesLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.templatesLoading = false;
        });
    },
    setNewEditedMeeting() {
      this.setEditedMeeting({
        id: "",
        title: "",
        timed_topics: false,
        starts_at: moment(this.selectedDay).format("YYYY-MM-DD"),
        duration: 60,
        user_ids: [this.currentUser.id],
      });
    },
    loadEditedMeeting() {
      this.selectedTemplateId = "";
      this.formStep = 0;
      this.dateMenu = false;
      this.oldStartsAt = this.editedMeeting.starts_at;
      let starts_at = this.editedMeeting.starts_at
        ? new Date(this.editedMeeting.starts_at)
        : new Date();

      let year = starts_at.getFullYear();
      let month = starts_at.getMonth() + 1;
      let day = starts_at.getDate();

      this.meetingDate = `${year}-${String(month).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;
      this.oldMeetingDate = this.meetingDate;
      this.meetingStartTime =
        String(starts_at.getHours()).padStart(2, "0") +
        ":" +
        String(starts_at.getMinutes()).padStart(2, "0");
      this.meetingTitle = this.editedMeeting.title || "";
      this.meetingColor = this.editedMeeting.color || "blue";
      this.meetingDescription = this.editedMeeting.description || "";
      this.timedTopics = this.editedMeeting.timed_topics;
      this.meetingDuration = this.editedMeeting.duration || 0;
      this.meetingEndTime = "";
      this.loading = false;
      this.meetingUserIds = this.editedMeeting.user_ids
        ? [...this.editedMeeting.user_ids]
        : [];
      if (
        this.editedMeeting.recurrent_meeting &&
        this.editedMeeting.recurrent_meeting.recurring
      ) {

        this.loadRecurringSettings(
          this.editedMeeting.recurrent_meeting.recurring
        );
        this.oldRuleString = this.rruleString;
      }

    },
    loadRecurringSettings(RRule_string) {
      //Load the RRule string as RRule object
      var RRuleObj = RRule.fromString(RRule_string);

      if (RRuleObj) {
        console.log("Edited meeting has recurring settings:", RRuleObj.options);
        var rule = RRuleObj.options;
        if (this.editedMeeting.recurrent_meeting && this.editedMeeting.recurrent_meeting.ends_at)
          rule.until = new Date(moment(this.editedMeeting.recurrent_meeting.ends_at));
        if (
          !rule.count &&
          !rule.until &&
          !rule.wkst &&
          !rule.byeaster &&
          rule.interval == 1
        ) {
          //It's a basic preset.
          //freq = YEARLY 0, MONTHLY 1, WEEKLY 2, DAILY 3, HOURLY 4, MINUTELY 5, or SECONDLY 6

          if (rule.freq == 3) {
            //Daily meetings
            this.selectedRecurrence = 1;
          } else if (
            rule.freq == 2 &&
            rule.bysetpos == null &&
            rule.byweekday &&
            rule.byweekday.length == 1
          ) {
            //Weekly meetings
            this.selectedRecurrence = 2;
          } else if (
            rule.freq == 2 &&
            rule.byweekday &&
            rule.byweekday.length == 5
          ) {
            //every week days
            const all_matches = [0, 1, 2, 3, 4].every((r) =>
              rule.byweekday.includes(r)
            );

            if (all_matches) this.selectedRecurrence = 6;
          } else if (
            rule.freq == 2 &&
            rule.bysetpos == null &&
            rule.byweekday &&
            rule.byweekday.length > 1
          ) {
            //Weekly meetings with multiple days, we need to handle it as a custom one
            this.handleCustomRecurrence(RRuleObj);
          } else if (
            rule.freq == 1 &&
            rule.bysetpos.length &&
            rule.bysetpos[0] != -1
          ) {
            //monthly meetings on first, second, third, fourth
            this.selectedRecurrence = 3;
          } else if (
            rule.freq == 1 &&
            rule.bysetpos.length &&
            rule.bysetpos[0] == -1
          ) {
            //monthly meetings on last
            this.selectedRecurrence = 4;
          } else if (rule.freq == 0) {
            //yealry
            this.selectedRecurrence = 5;
          }
        } else {
          // It's a custom recurring meeting.
          this.handleCustomRecurrence(RRuleObj);
        }
        //Fill basic presets

        // byeaster: null
        // byhour: [0]
        // byminute: [31]
        // bymonth: null
        // bymonthday: []
        // bynmonthday: []
        // bynweekday: null
        // bysecond: [12]
        // bysetpos: null
        // byweekday: null
        // byweekno: null
        // byyearday: null
        // count: null
        // dtstart: Wed Dec 22 2021 01:31:12 GMT+0100 (heure normale d’Europe centrale) {}
        // freq: 3 //YEARLY, MONTHLY, WEEKLY, DAILY, HOURLY, MINUTELY, or SECONDLY
        // interval: 1
        // tzid: null
        // until: null
        // wkst: 0
      }
    },
  },
  mounted() {
    this.loadEditedMeeting();
  },
  created() {},
  beforeDestroy() {},
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$emit("cleanNewEvent");
      } else {
        // reset
        this.selectedRecurrence = 0;

        // Set meetings date to the day displayed by the calendar ( should be before loading edited meeting)
        if (this.selectedDay)
          this.meetingDate = moment(this.selectedDay).format("YYYY-MM-DD");

        this.loadEditedMeeting();
        this.loadTemplates();
      }
    },
    selectedRecurrence(newValue, oldValue) {
      if (newValue == 8) {
        // 8 = go_to_custom
        // Save what was the previous settings selected so we can fall back on it when user click cancel on the custom recurrence dialog.
        this.previousSelectedRecurrence = oldValue;

        this.$refs.customRecurrenceSettings.dialog = true;
      }
    },
  },
};
</script>
