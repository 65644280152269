<template>
  <v-container fluid style="height: 100% ; background-color: white">
    <headerToolbar color="white" divider>
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("templates.templates") }}</b>
      </template>
    </headerToolbar>

    <v-row
      wrap
      no-gutters
      class="mx-auto pa-0"
      style="width: 100%; height: 100%"
    >
      <!-- <v-col cols="2">
        <projectsSidebar />
      </v-col> -->

      <template v-if="noResourceFetched && !search">
        <v-container fluid style="height: 100%" class="px-8 text-center">
          <v-row
            wrap
            no-gutters
            style="height: 100%"
            class="align-center justify-center"
          >
            <v-col cols="12" class="align-center justify-center">
              <v-col cols="12" class="text-center justify-center">
                <img height="260" class="pb-4" src="/images/templates.png" />
                <h2 class="pb-4">
                  {{ $t("templates.templateFeatureDesc") }}
                </h2>

                <!-- <span class="body-2">
                  {{ $t("followUps.followUpsDescription1") }}<br />
                  {{ $t("followUps.followUpsDescription2") }}
                </span> -->

                <v-alert
                  max-width="640"
                  border="left"
                  colored-border
                  type="info"
                  elevation="0"
                  class="mx-auto caption"
                  prominent
                  color=""
                >
                  <span class="caption">
                    {{ $t("templates.templateFeatureDesc2") }}
                  </span>

                  <template slot="prepend">
                    <v-icon class="ml-4" size="28" color="mid_grey"
                      >mdi-help</v-icon
                    >
                  </template>
                </v-alert>

                <!-- <p
                class="grey--text font-weight-medium"
                v-html="$t('misc.planAndPrepare')"
              ></p> -->
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <v-col cols="12"  v-else>
        <v-row dense no-gutter class="pt-2 pb-4">


            <v-text-field
              v-model="search"
               :label="$t('templates.searchTemplates')"
              outlined
              rounded
              solo
              flat
              dense
              class="denseTextField mr-4"
              prepend-inner-icon="mdi-magnify"
              style="float: right"
              hide-details
              clearable
            ></v-text-field>





        </v-row>
        <v-divider  />

        <v-row dense no-gutter>
          <v-col cols="12">
            <paginatedTable
              :headers="headers"
              :graphqlQuery="getGraphqlQuery()"
              :refresh="refreshIndex"
              resourceName="meeting_templates"
              :filter="filter"
              :customSlots="['name', 'created_at', 'action']"
              cursorAsPointer
              @noResourceFetched="noResourceFetched = true"
            >
              <template v-slot:created_at="{ slotScope: { item } }">
                {{ timeToDate(item.created_at) }}
              </template>

              <template v-slot:action="{ slotScope: { item } }">
                <v-btn
                  small
                  text
                  depressed
                  @click.stop="useTemplate(item.id)"
                  color="button_blue"
                >
                  {{ $t("templates.useTemplate") }}
                </v-btn>

                <v-tooltip left color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      @click.stop="deleteTemplate(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("misc.remove") }}</span>
                </v-tooltip>
              </template>

              <template v-slot:name="{ slotScope: { item } }">
                {{ item.name }}
              </template>
              <template v-slot:no-data="">
                <strong>{{ $t("templates.empty") }}</strong>
              </template>
            </paginatedTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  <meetingForm   ref="meetingForm" hideButton/>
    <!-- <v-container fluid fill-height align-center>
      <v-row no-gutters class="text-center">
        <v-col cols="12">
          <v-img
            class="mx-auto mb-8"
            width="320"
            src="images/svg_images/underconstruction.svg"
          >
          </v-img>
          <h3
            class="primary--text"
            v-html="$t('templates.featureNotAvailable')"
          >
          </h3>
        </v-col>
      </v-row>
    </v-container> -->
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import paginatedTable from "@/components/shared/paginatedTable";
import date from "@/mixins/date";
import MEETING_TEMPLATES_INDEX from "@/graphql/meeting_templates/paginated_index.gql";
import { mapActions, mapGetters } from "vuex";
import meetingForm from "@/components/meetings/meetingForm";

export default {
  name: "templates_index",
  mixins: [date],
  components: {
    headerToolbar,
    paginatedTable,
    meetingForm
  },
  data() {
    return {
      noResourceFetched: false,
      search: "",
      refreshIndex: 0,
      headers: [
        {
          text: this.$t("templates.templateName"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("misc.createdAt"),
          align: "start",
          sortable: false,
          value: "created_at",
        },
        {
          text: this.$t("misc.actions"),
          align: "end",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
    filter() {
      if (this.selectedOrganisationId)
        return `organisation_id == '${this.selectedOrganisationId}'`;
      return "";
    },
  },
  methods: {
    ...mapActions(["openDestroyDialog"]),
    getGraphqlQuery() {
      return MEETING_TEMPLATES_INDEX;
    },
    deleteTemplate(template) {
      this.openDestroyDialog({
        id: template.id,
        model: "meeting_template",
        onDestroy: () => {
          this.refreshIndex++;
        },
        title: this.$t("templates.removeAlertTitle"),
        message: this.$t("templates.removeAlertMessage").replace(
          "<template_name>",
          template.name
        ),
      });
    },
   useTemplate(templateId) {
      const meetingFormComponent = this.$refs.meetingForm;
      meetingFormComponent.dialog = true;
      meetingFormComponent.selectedTab = "loadTemplate";
      meetingFormComponent.selectedTemplateId = templateId;
    },
  },
  mounted() {},
  created() {
    document.title = this.$t("navigationMenu.templates");
    this.$amplitude.getInstance().logEvent("Page: Templates");
  },
  watch: {
    search() {
      this.noResourceFetched = false;
    }
  }
};
</script>

<style>
.theme--light.v-application {
  background-color: white  !important;
}
</style>



